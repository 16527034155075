<template>
    <EmailFormVue ref="emailForm" :isLoading="isLoading" @store="store" :emailDetail="emailDetail"></EmailFormVue>
</template>
<script>
import EmailFormVue from './EmailForm.vue'
import axios from 'axios'
export default {

   components:{
    EmailFormVue
   },
   data(){
    return {
       emailDetail: {
    "name": "",
    "subject": "",
    "email_body": "",
    "sender_name": "",
    "sender_email_address": "",
    "reply_to_email_address": ""
},
errors: {},
isLoading:false,
    }
   },
   methods:{
    open(){
        this.$refs.emailForm.open();
          this.emailDetail= {
    "name": "",
    "subject": "",
    "email_body": "",
    "sender_name": "",
    "sender_email_address": "",
    "reply_to_email_address": ""
}
    },
   async  store(){
    this.isLoading=true;
       await axios.post('api/v1/email-templates',this.emailDetail).then(()=>{
            this.toast('Email Created');
            this.$refs.emailForm.close();
            this.$emit('reload')
        }).catch((e)=> {this.toast('Error while creating email'); console.log(e)});
         this.isLoading=false;
    },
    toast(text){
          this.$toasted.show(text, {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
    }
   }
}
</script>